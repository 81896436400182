import React, { Component } from 'react';
import { Layout } from './components/Layout';
import './custom.css';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <div className="maintenance-content-container">
                    <h1 className="heading">We'll be right back...</h1> <br />
                    <h2 className="sub-heading">Sorry for the inconvenience! Our team is making a few updates to FollowMyHealth. We'll be back very soon.</h2>
                    <div className="mobile-background-container"></div>
                </div>
            </Layout>
        );
    }
}
