import React, { Component } from 'react';
import { Container } from 'reactstrap';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div className="maintenance-framework">
                <div className="maintenance-header">
                    <img className="header-logo" src="fmhlogo.png" />
                </div>
                <div className="maintenance-content">
                        {this.props.children}
                </div>
            </div>

        );
    }
}
